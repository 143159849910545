/**
 * Import Swiper from the node modules
 * Looking for where the CSS is imported? Check main.scss!
 * @see https://swiperjs.com/get-started
 */
import Swiper from 'swiper';

/**
 * Import the Navigation and Pagination modules for this specific slider
 * and configure Swiper to use those modules
 */

import { Navigation, Pagination, Autoplay } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay]);

class GallerySlider {
  /**
   * Set up the element(s) for the Swiper instance
   */
  constructor() {
    this.sliderObj = '.gallery-slider';
    this.pagination = '.swiper-pagination';
    this.nextEl = '.swiper-button-next';
    this.prevEl = '.swiper-button-prev';
  }

  /**
   * Initialize the Swiper instance and its settings
   * @see https://swiperjs.com/swiper-api
   */
  init() {
    const swiper = new Swiper(this.sliderObj, {
      loop: true,

      autoplay: {
        delay: 5000,
      },

      pagination: {
        el: this.pagination,
        type: 'fraction',
        formatFractionCurrent: function(number) {
          if (number < 10) {
            number = '0' + number;
          }
          return number;
        },
        formatFractionTotal: function(number) {
          if (number < 10) {
            number = '0' + number;
          }
          return number;
        },
      },

      navigation: {
        nextEl: this.nextEl,
        prevEl: this.prevEl,
      },
    });
    swiper.init();
  }
}

export default GallerySlider;
