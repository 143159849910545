export default {
  init() {
    // JavaScript to be fired on the home page

    // Move homepage highlighted services in the DOM
    window.onload = function serviceMove() {
      let highlighted = document.querySelector('#highlighted-services');
      let ourWork = document.querySelector('#our-work-content');

      //check if the highlighted-services element exists
      if (highlighted) {
        // Move items in the DOM
        ourWork.append(highlighted);
      }
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
