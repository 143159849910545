// import external dependencies
import 'jquery';

// import font awesome
import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowRight,
  faArrowLeft,
  faPlus,
  faPaintBrush,
  faDesktop,
  faChartBar,
  faSearch,
  faExternalLinkAlt,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faUser,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagram,
  faLinkedin,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faArrowLeft,
  faArrowRight,
  faPlus,
  faFacebookSquare,
  faTwitterSquare,
  faInstagram,
  faLinkedin,
  faYoutubeSquare,
  faPaintBrush,
  faDesktop,
  faChartBar,
  faSearch,
  faExternalLinkAlt,
  faChevronRight,
  faChevronLeft,
  faCheck,
  faCalendar,
  faUser
)
dom.watch();

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import contact from './routes/contact';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Contact page
  contact,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
