// Create a new Navigation object
class Navigation {

  // Initialize object properties
  constructor() {
    this.desktopNav = $( '.desktop-navigation' );
    this.dropdown = $( '.dropdown' );
    this.dropdownOpen = $( '.dropdown.open' );
    this.header = $( '.main-header .container' );
    this.mobileNav = $( '.mobile-navigation' );
    this.page = $( 'body' );
    this.scrimDesktop = $( '.scrim-desktop' );
    this.scrimMobile = $( '.scrim-mobile' );
    this.subMenu = $( '.sub-menu' );
    this.toggle = $( '.menu-toggle' );
    this.toggleDropdown = $( '.dropdown-toggle' );
    this.triggers = [this.toggle, this.scrimMobile];
  }

  /**
   * Initialize the mobile menu toggle button
   */
  initToggle() {
    // Apply listeners to each mobile menu trigger as defined in the object properties
    $.each( this.triggers, function( i, obj ) {

      // On click listener, toggle all relevant open/close classes
      $( obj ).on( 'click', function( e ) {
        e.preventDefault();
        this.toggle.toggleClass( 'menu-open' );
        this.mobileNav.toggleClass( 'show' ).toggleClass( 'hide' );
        this.dropdown.removeClass( 'open' );
        this.scrimMobile.toggleClass( 'menu-open' );
        this.page.toggleClass( 'no-scroll' );
      }.bind( this )); // Bind the class object to keep context

    }.bind( this )); // Bind the class object to keep context
  }

  /**
   * Initialize the mobile menu dropdown togglers (carets)
   */
  initToggleDropdown() {
    // On click listener to open/close dropdown sub menus
    this.toggleDropdown.on( 'click', function( e ) {
      e.preventDefault();
      $( this ).parent().parent( '.dropdown' ).toggleClass( 'open' );
      $( this ).parent().siblings().find( '.dropdown' ).removeClass( 'open' );
    });

    // Set the calculated height of the mobile sub menus for slide down effect
    // Loop through all sub-menus
    $( '.mobile-navigation .nav-menu .menu-item.dropdown .sub-menu' ).each( function() {
      var subMenuHeight = 0;

      // Loop through each menu-item in the sub-menu
      $( this ).children( '.menu-item' ).each ( function () {
        var itemHeight = $( this ).outerHeight( true );

        // Calculate sub-menu height
        subMenuHeight = parseFloat( subMenuHeight ) + parseFloat( itemHeight );

        // Reset menu-item height for next iteration
        itemHeight = 0;
      });

      // Pass the new height to a data-* attribute for this iteration
      $( this ).css( '--calculated-height', subMenuHeight + 'px' );

      // Reset sub-menu height for next iteration
      subMenuHeight = 0;
    });
  }

  /**
   * Initialize desktop menu dropdown scrims.
   * This method calculates each dropdown scrim height
   * and applies the new height to the elements.
   */
  initDropdownScrims() {
    // Resize each dropdown scrim to the corresponding sub-menu height
    var navHeight = this.desktopNav.outerHeight();
    var scrimHeight = 0;

    // Loop through all top level menu items with dropdowns
    $( '.desktop-navigation .nav-menu .menu-item.dropdown' ).each( function() {

      // Calculate new scrim height using nav and sub-menu
      scrimHeight = parseFloat( navHeight ) + parseFloat( $( this ).find( '.sub-menu' ).outerHeight( true ) );

      // Apply the new height to this iteration
      $( this ).find( '.scrim-desktop' ).height( scrimHeight );

      // Reset scrim height for next iteration
      scrimHeight = 0;
    });
  }

  /**
   * Initialize desktop menu hover listeners
   */
  initHoverListener() {
    // Binding the class object will not work here,
    // so, use the `that`/`self` var method to allow `this` to keep context.
    let self = this;

    // On mouseover listener for top level dropdowns, open sub menu
    this.desktopNav.find( this.dropdown ).on( 'mouseover', function() {
      var target = $( this ).data( 'target-menu-id' );
      $( this ).addClass( 'open' ); // dropdown
      $( this ).find( self.scrimDesktop ).addClass( 'menu-open' ); // scrim
      $( this ).parent().parent().find( `[data-menu-id='${target}']` ).addClass( 'reveal' ); // sub-menu
      $( this ).nextAll( $( '.menu-item a' ) ).addClass( 'highlight' ); // next siblings down the line
    });

    // On mouseleave listener for top level dropdowns, close sub menu
    this.desktopNav.find( this.dropdown ).on( 'mouseleave', function() {
      var target = $( this ).data( 'target-menu-id' );
      $( this ).removeClass( 'open' ); // dropdown
      $( this ).find( self.scrimDesktop ).removeClass( 'menu-open' ); // scrim
      $( this ).parent().parent().find( `[data-menu-id='${target}']` ).removeClass( 'reveal' ); // sub-menu
      $( this ).nextAll( $( '.menu-item a' ) ).removeClass( 'highlight' ); // next siblings down the line
    });
  }

  /**
   * Handle page scroll callback
   */
  handleScroll() {
    // Close any open dropdowns on the desktop menu
    this.dropdown.removeClass( 'open' );
    this.scrimDesktop.removeClass( 'menu-open' );
    this.subMenu.removeClass( 'reveal' );
  }

  /**
   * Handle page resize callback
   */
  handleResize() {
    // Close the mobile menu, reset the toggle button, and clasoe any open dropdowns
    this.toggle.removeClass( 'menu-open' );
    this.mobileNav.removeClass( 'show' ).addClass( 'hide' );
    this.dropdown.removeClass( 'open' );
    this.scrimMobile.removeClass( 'menu-open' );
    this.page.removeClass( 'no-scroll' );

    // Re-initialize the dropdown scrims for the desktop menu
    this.initDropdownScrims();
  }

  /**
   * Method callback to initialize the Navigation object
   */
  init() {
    this.initToggle();
    this.initToggleDropdown();
    this.initDropdownScrims();
    this.initHoverListener();

    // Add window resize listener with debounce
    $( window ).on( 'resize', function() {
      setTimeout( this.handleResize(), 500 );
    }.bind( this )); // Bind the class object to keep context

    // Add window scroll listener
    $( window ).on( 'scroll', function() {
      this.handleScroll();
    }.bind( this )); // Bind the class object to keep context
  }
}

// Export class as default
export default Navigation;
