import GallerySlider from './gallery-slider';
import Navigation from './navigation';

export default {
  init() {
    // JavaScript to be fired on all pages

    /**
     * Initialize the navigation object
     */
    const navigation = new Navigation();
    navigation.init();

    /*
     * Initializes the slider for the Brand Gallery Slider block
     */
    const gallerySlider = new GallerySlider();
    gallerySlider.init();

    // Initialize the slider object
    var slider = $('.services-slider');

    // bindings to init event must be registered before slider initialization
    slider.on('init reInit afterChange', function(event, slick) {
      var currentSlide = (slick.currentSlide ? slick.currentSlide : 0) + 1;
      if (currentSlide < 10) {
        currentSlide = '0' + '' + currentSlide;
      }
      $('.current').html(currentSlide);
    });

    slider.on('init', function(event, slick) {
      var totalSlides = slick.slideCount;
      if (totalSlides < 10) {
        totalSlides = '0' + '' + totalSlides;
      }
      $('.total').html(totalSlides);
    });

    $('.services-slider').slick({
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
      infinite: true,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000,
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
