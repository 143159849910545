export default {
  init() {
    // JavaScript to be fired on the contact us page

    // Gravity form style
    $( '.gform_next_button.button' ).addClass( 'btn' ).addClass( 'dark-outline' );

    /**
     * jQuery UI - Slider Object
     */
    // Budget Range Slider with min and max range set
    $( '#slider-budget-range' ).slider({
      range: true,
      min: 0,
      max: 100,
      values: [ 10, 20 ],
      slide: function( event, ui ) {
        $( '.budget-range input[type=text]' ).val( '$' + ui.values[ 0 ] + ' - ' + ui.values[ 1 ] + 'K' );
      },
    });
    // Update the DOM
    $( '.budget-range input[type=text]' ).val( '$' + $( '#slider-budget-range' ).slider( 'values', 0 ) + ' - ' + $( '#slider-budget-range' ).slider( 'values', 1 ) + 'K');

    // Time Range Slider with min and max range set
    $( '#slider-time-range' ).slider({
      range: 'max',
      min: 1,
      max: 56,
      value: 4,
      slide: function( event, ui ) {
        $( '.time-range input[type=text]' ).val( ui.value );
      },
    });
    // Update the DOM
    $( '.time-range input[type=text]' ).val( $( '#slider-time-range' ).slider( 'value' ) );
  },
  finalize() {
    // JavaScript to be fired on the contact us page, after the init JS
  },
};

// Add an aria-hidden attribute to asterisk in the form
document.addEventListener('DOMContentLoaded', function() {

  // Find all spans containing asterisks within the form
  const requiredAsteriskSpans = document.querySelectorAll('.gform_body span.gfield_required_asterisk');

  // Loop through each span and set aria-hidden attribute to true
  requiredAsteriskSpans.forEach(span => {
    span.setAttribute('aria-hidden', 'true');
  });
});
